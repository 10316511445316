import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as DOMPurify from "dompurify";
import { Interweave, ALLOWED_TAG_LIST } from "interweave";
import { checkIfStringIsJson } from "utils";
import draftToHtml from "draftjs-to-html";

const HTMLRenderViewContainer = styled.div`
  strong {
    font-weight: 500;
  }
  p {
    margin-bottom: 1.2rem;
    min-height: 1px;
  }
  em {
    font-style: italic;
  }
  a {
    color: var(--color-accent-prim);
    cursor: pointer;
    font-family: "nunito";
  }
  iframe {
    width: 100%; /* Full width of the container */
    height: auto; /* Automatically adjusts the height based on aspect ratio */
    aspect-ratio: 16 / 9; /* Specifies the 16:9 aspect ratio */
    border: none; /* Optional: removes default border */
    margin: 1.2rem auto;
  }
`;

const allowList = ALLOWED_TAG_LIST.concat(["iframe"]);

const HTMLRenderView = ({ content }) => {
  const isJson = checkIfStringIsJson(content);
  const parsedContent = isJson ? draftToHtml(JSON.parse(content)) : content;

  return (
    <HTMLRenderViewContainer>
      {isJson ? (
        <Interweave
          allowList={allowList}
          content={DOMPurify.sanitize(parsedContent, {
            ADD_TAGS: ["iframe"], // Add iframe to the list of allowed tags
            ADD_ATTR: ["width", "height", "src", "frameborder"], // Allow relevant iframe attributes
            FORBID_TAGS: [], // Ensure iframe is not being explicitly forbidden,
          })}
        />
      ) : (
        <p>
          <Interweave
            allowList={allowList}
            content={DOMPurify.sanitize(parsedContent, {
              ADD_TAGS: ["iframe"], // Add iframe to the list of allowed tags
              ADD_ATTR: ["width", "height", "src", "frameborder"], // Allow relevant iframe attributes
              FORBID_TAGS: [], // Ensure iframe is not being explicitly forbidden
            })}
          />
        </p>
      )}
    </HTMLRenderViewContainer>
  );
};

export default HTMLRenderView;
