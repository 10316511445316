import { getAxiosClient } from "../axios";

export const getOrganization = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getAdminOrganization = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/organizations/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrganizations = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations`, { params: { ...params } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const editOrganization = async (id, data = { name: "name", description: "" }) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const uploadLogo = async (id, e) => {
  const file = e.target.files[0];
  const axiosClient = await getAxiosClient();

  const formData = new FormData();
  formData.append("file", file);

  return await axiosClient
    .put(`/organizations/${id}/logo`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const uploadCover = async (id, e) => {
  const file = e.target.files[0];
  const axiosClient = await getAxiosClient();

  const formData = new FormData();
  formData.append("file", file);

  return await axiosClient
    .put(`/organizations/${id}/cover`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrganizationProfileSection = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/profilesections`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const AddOrganizationProfileSection = async (id, data = { title: "title", text: "text", isVisible: false }) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${id}/profilesections`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const EditOrganizationProfileSection = async (id, sectionId, title, text, isVisible) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}/profilesections/${sectionId}`, {
      title,
      text,
      isVisible,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const EditImageOrganizationProfileSection = async (id, sectionId, file) => {
  const axiosClient = await getAxiosClient();

  const formData = new FormData();
  formData.append("file", file);

  return await axiosClient
    .put(`/organizations/${id}/profilesections/${sectionId}/image`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const deleteOrganizationProfileSection = async (id, sectionId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/organizations/${id}/profilesections/${sectionId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const AddOrganisationAddress = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${id}/addresses`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const EditOrganisationAddress = async (id, addressID = 0, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}/addresses/${addressID}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const AddSupportMessage = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${id}/support`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const AddOrganizationLink = async (id, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${id}/links`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const UpdateOrganizationLink = async (id, linkId, data) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}/links/${linkId}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const DeleteOrganizationLink = async (id, linkId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/organizations/${id}/links/${linkId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addOrganizationDocument = async (id, document) => {
  const axiosClient = await getAxiosClient();

  const formData = new FormData();
  formData.append("file", document.file);
  formData.append("name", document.name);

  if (document.languageCode) formData.append("languageCode", document.languageCode);

  return await axiosClient
    .post(`/organizations/${id}/documents`, formData, { "content-type": "multipart/form-data" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const editOrganizationDocument = async (id, documentId, document) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}/documents/${documentId}`, document)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const deleteOrganizationDocument = async (id, documentId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/organizations/${id}/documents/${documentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const getOrganizationDocumentContent = async (id, documentId, filename, isLink = true) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}/documents/${documentId}/content`, { responseType: "blob" })
    .then((response) => {
      if (isLink) {
        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const resetDemoData = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/demodata/reset`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFollowedOrganizations = async (params) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/followedorganizations/`, { params: { ...params } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const followOrganization = async (organization) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/me/followedorganizations/`, organization)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const unfollowOrganization = async (organizationID) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/me/followedorganizations/${organizationID}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getFeaturedEvents = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/me/featuredevents`, { params: { OrgIds: id } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addTranslation = async (id, translation) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${id}/translations`, translation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateTranslation = async (id, translation) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}/translations`, translation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const removeTranslation = async (id, languageCode) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/organizations/${id}/translations/${languageCode}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getProfileSectionTranslations = async (organizationId, id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${organizationId}/profilesections/${id}/translations`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const addProfileSectionTranslation = async (organizationId, id, translation) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/organizations/${organizationId}/profilesections/${id}/translations`, translation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateProfileSectionTranslation = async (organizationId, id, translation) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${organizationId}/profilesections/${id}/translations`, translation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const removeProfileSectionTranslation = async (organizationId, id, languageCode) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .delete(`/organizations/${organizationId}/profilesections/${id}/translations/${languageCode}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const moveProfileSequenceUp = async (orgId, id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${orgId}/profilesections/${id}/move-up`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const moveProfileSequenceDown = async (orgId, id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${orgId}/profilesections/${id}/move-down`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrgCategories = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/categories`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const updateOrgCategory = async (id, categoryId, subcategoryId) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}/category`, { categoryId, subcategoryId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};