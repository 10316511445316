import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddButton from "../../../components/UI/Buttons/AddButton";
import Loader from "components/UI/Loader";
import {
  AddOrganizationProfileSection,
  getOrganizationProfileSection,
  deleteOrganizationProfileSection,
  EditOrganizationProfileSection,
  moveProfileSequenceDown,
  moveProfileSequenceUp,
} from "api/services/organization";
import ProfileSection from "./ProfileSection";
import { UseErrorContext } from "contexts";

const WAIT_INTERVAL = 1000;

const Container = styled.div`
  .button__container {
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex-direction: row;
  }
  .button__container:before,
  .button__container:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid var(--color-light-grey);
    margin: auto;
  }
  .button__container:before {
    margin-right: 10px;
  }
  .button__container:after {
    margin-left: 10px;
  }
`;

const ProfileTabs = ({ profileData, _setProfileData, id, refreshData }) => {
  const [tabs, setTabs] = useState(profileData.profileSection || []);
  const [isLoading, setIsLoading] = useState(false);
  const { updateError } = UseErrorContext();
  const [isEdited, setIsEdited] = useState(0);

  const addTab = async () => {
    if (isEdited > 0) {
      updateError({ message: "Save edited section(s) first." });
      return;
    }
    setIsLoading(true);
    AddOrganizationProfileSection(id)
      .then(async (value) => {
        const resp = await getOrganizationProfileSection(id);
        _setProfileData({ ...profileData, profileSection: resp });
        setIsLoading(false);
      })
      .catch((e) => {
        updateError(err);
        setIsLoading(false);
      });
  };

  const moveSequenceUp = async (sectionId) => {
    try {
      setIsLoading(true);
      await moveProfileSequenceUp(id, sectionId);
      await refreshData();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      updateError(err);
    }
  };

  const moveSequenceDown = async (sectionId) => {
    try {
      setIsLoading(true);
      await moveProfileSequenceDown(id, sectionId);
      await refreshData();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      await updateError(err);
    }
  };

  const editTab = async (index, title, text) => {
    setIsLoading(true);
    EditOrganizationProfileSection(id)
      .then(async (value) => {
        const resp = await getOrganizationProfileSection(id, index, title, text);
        _setProfileData({ ...profileData, profileSection: resp });
        setIsLoading(false);
      })
      .catch((e) => {
        updateError(err);
        setIsLoading(false);
      });
  };

  const removeTab = (index) => {
    setIsLoading(true);
    deleteOrganizationProfileSection(id, index)
      .then(async (value) => {
        const resp = await getOrganizationProfileSection(id);
        _setProfileData({ ...profileData, profileSection: resp });
        setIsLoading(false);
      })
      .catch((e) => {
        updateError(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setTabs(profileData.profileSection || []);
  }, [profileData]);

  const onChangeEdited = (val) => {
    const x = val ? isEdited + 1 : isEdited > 0 ? isEdited - 1 : 0;
    setIsEdited(x);
  };

  return (
    <Container>
      {tabs.map((item, idx) => (
        <ProfileSection
          item={item}
          key={idx}
          idx={idx}
          isLoading={isLoading}
          removeTab={removeTab}
          organizationId={id}
          onChangeEdited={onChangeEdited}
          moveSequenceUp={moveSequenceUp}
          moveSequenceDown={moveSequenceDown}
          refreshData={refreshData}
          isFirst={idx == 0}
          isLast={idx == tabs.length - 1}
        />
      ))}
      {tabs.length < 5 && <div className="button__container">{isLoading ? <Loader center={false} /> : <AddButton _onClick={() => addTab()} />}</div>}
    </Container>
  );
};

export default ProfileTabs;
